import { Badge, Box, Button, Link, Modal, Table, Tag, useToast } from "@teamfabric/copilot-ui";
import { useEffect, useState } from "react";
import { COPILOT_ROUTES } from "@teamfabric/copilot-utilities";
import {
  StyledBadge,
  StyledBodyText,
  StyledH4,
  StyledHead,
  StyledTags,
} from "./style";
import { BadgeStatus } from "@teamfabric/copilot-ui/dist/components/badge/Badge.type";
import {
  CHANNEL_LIST_API,
  CREATE_CHANNEL_PERMISSION,
} from "../../lib/constants/constants";
import {
  CHANNELS_COL1,
  CHANNELS_COL2,
  CHANNELS_COL3,
  CHANNELS_COL4,
  CHANNELS_EMPTY_HEADER_TEXT,
  CHANNELS_HEAD,
  CHANNELS_SUBHEAD,
  CHANNEL_STATUS,
  CREATE_CHANNEL,
  DEFAULT_CHANNEL,
  DEFAULT_TEXT,
  MODAL_MESSAGES,
  PAGE_SIZE,
} from "../../lib/constants/textProperties";
import { useHistory } from "react-router-dom";
import { ChannelListProps } from "../utils/types";
import { validatePermission } from "../utils/permissions";
import { multichannelAPI } from "../utils/multiChannelAPI";
import { channelStatus } from "./types";
import { generateRequestId } from "../utils/random-generator";

export function getTableCaption(page: number, totalRecords: number) {
  return `Showing ${PAGE_SIZE * (page - 1) + 1} - ${
    PAGE_SIZE * page > totalRecords ? totalRecords : PAGE_SIZE * page
  } of ${totalRecords}`;
}

const getTags = (values: string[], defaultValue: string, id: string) => {
  return (
    <StyledTags>
      {defaultValue && defaultValue.length !== 0 && (
        <Tag
          id={`${id}-${defaultValue}`}
          primaryLabel={defaultValue}
          secondaryLabel={DEFAULT_TEXT}
        />
      )}
      {values
        .filter((value) => value !== defaultValue)
        .map((value, index) => (
          <Tag
            id={`${id}-${value}-${index}`}
            key={`${value}${id}`}
            primaryLabel={value}
          />
        ))}
    </StyledTags>
  );
};

const getBadge = (value: string, isDefault: boolean) => {
  let bStatus = "";
  switch (value) {
    case "ACTIVE":
      bStatus = "success";
      break;
    case "INACTIVE":
      bStatus = "error";
      break;
    default:
      bStatus = "default";
      break;
  }

  return (
    <StyledBadge>
      <Badge label={value} status={bStatus as BadgeStatus} />
      {isDefault && (
        <Badge label={DEFAULT_CHANNEL} variant="secondary" asset="icon" />
      )}
    </StyledBadge>
  );
};

const getLink = (
  label: string,
  id: string,
  status: string,
  handleUnarchiveModal: (id: string, s: boolean) => void
) => {
  const history = useHistory();
  return (
    <Link
      label={label}
      size="medium"
      mode="inline"
      onClick={() => {
        if (status != CHANNEL_STATUS.ARCHIVED) {
          history.push(COPILOT_ROUTES.settings.channels.details + `?channelId=${id}`);
        } else {
          handleUnarchiveModal(id, true);
        }
      }}
    />
  );
};
const ChannelList = ({ isLoading, error, channelList }: ChannelListProps) => {
  const history = useHistory();
  const showToast = useToast();
  const [page, setPage] = useState(1);
  const [channelTableData, setChannelTableData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedChannelId, setSelectedChannelId] = useState<string>("");
  const totalRecords = channelList?.length;
  const allowChannelCreate = validatePermission(CREATE_CHANNEL_PERMISSION);

  useEffect(() => {
    channelList.length &&
      setChannelTableData(
        prepareTableData().slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE)
      );
  }, [page, channelList]);

  const handleUnarchiveModal = (id: string, showModal: boolean) => {
    setSelectedChannelId(id);
    setIsModalVisible(showModal);
  }
  const prepareTableData = () => {
    const data = channelList?.map((channel) => {
      return {
        id: channel.id,
        data: {
          "Channel name": {
            value: channel.name,
            onRender: (props) => {
              return getLink(
                props.value,
                channel.id,
                channel.status,
                handleUnarchiveModal
              );
            },
          },
          Locales: {
            value: channel.metadata,
            onRender: (props) => {
              return getTags(
                props.value.locales,
                props.value?.defaultLocale,
                channel.id
              );
            },
          },
          Currencies: {
            value: channel.metadata,
            onRender: (props) => {
              return getTags(
                props.value.currencies,
                props.value?.defaultCurrency,
                channel.id
              );
            },
          },
          Status: {
            value: channel.status,
            onRender: (props) => {
              return getBadge(props.value, channel.isDefault);
            },
          },
        },
      };
    });
    return data;
  };
  const handleCreateButtonClick = () => {
    history.push(COPILOT_ROUTES.settings.channels.create);
  };
  const handleChannelUnArchive = async () => {
    try {
      const selectedChannel = channelList?.filter(channel => channel.id === selectedChannelId);
      if (selectedChannelId && selectedChannel?.length > 0) {
        await multichannelAPI.put(
          `${CHANNEL_LIST_API}/${selectedChannelId}`,
          {
            ...selectedChannel[0],
            status: channelStatus.Inactive
          },
          {
            headers: {
              "Content-Type": "application/json",
              "x-fabric-request-id": generateRequestId(),
            },
          }
        );
        history.push(COPILOT_ROUTES.settings.channels.details + `?channelId=${selectedChannelId}`);
      } else {
        showToast({
          id: "create-error-toast",
          label: "No Channel selected",
          isDismissable: true,
          showIcon: true,
          variant: "error"
        });
      }
    } catch(error) {
      showToast({
        id: "create-error-toast",
        label: error?.response?.data?.message,
        isDismissable: true,
        showIcon: true,
        variant: "error"
      });
    }
  }
  return (
    <Box padding={{ left: 6, right: 6, bottom: 6 }}>
      <StyledHead>
        <div>
          <StyledH4>{CHANNELS_HEAD}</StyledH4>
          <StyledBodyText>{CHANNELS_SUBHEAD}</StyledBodyText>
        </div>
        <div>
          {allowChannelCreate && (
            <Button
              onClick={handleCreateButtonClick}
              text={CREATE_CHANNEL}
              dataTestid="create-channel"
              icon="Add"
              variant="primary"
            />
          )}
        </div>
      </StyledHead>
      <Table
        columns={[
          { title: CHANNELS_COL1 },
          { title: CHANNELS_COL2 },
          { title: CHANNELS_COL3 },
          { title: CHANNELS_COL4, width: 275 },
        ]}
        dataTestidPrefix="channels-"
        data={channelTableData}
        loading={isLoading}
        showPagination={totalRecords > PAGE_SIZE}
        tableCaption={
          totalRecords > PAGE_SIZE && getTableCaption(page, totalRecords)
        }
        emptyTableProps={{
          headerText: CHANNELS_EMPTY_HEADER_TEXT,
          description: error,
          buttons: [],
        }}
        customPaginationProps={{
          perPage: PAGE_SIZE,
          totalRecords: totalRecords,
          activePageNumber: page,
          handlePagination: (page) => {
            setPage(page);
          },
        }}
      ></Table>
      <Modal
        size="small"
        headerText={MODAL_MESSAGES.UNARCHIVE_HEADER}
        description={MODAL_MESSAGES.UNARCHIVE_DESCRIPTION}
        isVisible={isModalVisible}
        onClose={() => {
          setIsModalVisible(false);
        }}
        footerButtons={[
          {
            text: MODAL_MESSAGES.SECONDARY_BUTTON,
            variant: "secondary",
            onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              setIsModalVisible(false);
            },
          },
          {
            text: MODAL_MESSAGES.UNARCHIVE_PRIMARY,
            variant: "primary",
            onClick: handleChannelUnArchive
            //onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {}, This need to be handled for the API integration
          },
        ]}
      ></Modal>
    </Box>
  );
};

export default ChannelList;
