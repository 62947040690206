import { COPILOT_ROUTES } from "@teamfabric/copilot-utilities";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ChannelListPage from "../Pages/ChannelList/ChannelListPage";
import ChannelDetails from "../Pages/ChannelDetails";
import CreateChannel from "../Pages/Channel/Create";
import { BreadcrumbProps, CopilotUIProvider } from "@teamfabric/copilot-ui";

declare global {
  interface Window {
    updateBreadcrumb: (data: BreadcrumbProps['data']) => void;
  }
}
export default function App() {
  return (
    <CopilotUIProvider>
      <BrowserRouter>
        <Switch>
          <Route path={COPILOT_ROUTES.settings.channels.basePath} component={ChannelListPage} exact />
          <Route
            path={COPILOT_ROUTES.settings.channels.details}
            component={ChannelDetails}
            exact
          />
          <Route
            path={COPILOT_ROUTES.settings.channels.create}
            component={CreateChannel}
            exact
          />
        </Switch>
      </BrowserRouter>
    </CopilotUIProvider>
  );
}
