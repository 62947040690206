import { theme } from "@teamfabric/copilot-ui";
import styled from "styled-components";

export const StyledTags = styled.div`
  display: flex;
  > div {
    margin-right: ${theme.space[1]};
  }
`;

export const StyledBadge = styled.div`
  display: flex;
  > span {
    margin-right: ${theme.space[2]};
  }
`;

export const StyledH4 = styled.div`
  ${theme.typography.h4};
  margin-bottom: ${theme.space[1]};
`;

export const StyledBodyText = styled.div`
  ${theme.typography.body1.regular};
  margin-bottom: ${theme.space[5]};
`;

export const StyledHead = styled.div`
  margin-top: ${theme.space[6]};
  display: flex;
  justify-content: space-between;
`;
