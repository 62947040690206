import { ChangeEvent, useEffect, useState } from "react";
import { COPILOT_ROUTES } from "@teamfabric/copilot-utilities";
import {
  StyledBodyText,
  StyledH4,
  StyledHead,
} from "../Pages/ChannelList/style";
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Dropdown,
  DropdownOptionProps,
  Input,
  Modal,
  MultiSelect,
  Switch,
  Tooltip,
  theme,
  useToast,
} from "@teamfabric/copilot-ui";
import { StyledH5 } from "./style";
import { MultiSelectOptionProp } from "@teamfabric/copilot-ui/dist/components/multiSelect";
import {
  ACTIVE_LABEL,
  ADDITIONAL_CURRENCIES,
  ADDITIONAL_LOCALES,
  ARCHIVE,
  CANCEL_BUTTON,
  CHANGES_SAVED_SUCCESS,
  CHANNEL_LABEL,
  CHANNEL_STATUS,
  CHANNEL_TOOLTIP_MESSAGES,
  CLOSE,
  CURRENCIES,
  DEFAULT_CURRENCY,
  DEFAULT_CURRENCY_MISSING_ERROR,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_LOCALE,
  DEFAULT_LOCALE_MISSING_ERROR,
  DEFAULT_TEXT,
  FAILURE_ARCHIVE_CHANNEL,
  LOCALES,
  MODAL_MESSAGES,
  NAME_LABEL,
  OFF_LABEL,
  ON_LABEL,
  READ_ONLY,
  SAVE_BUTTON,
  SET_AS_DEFAULT,
  SUCCESSFUL_ARCHIVE_CHANNEL,
} from "../lib/constants/textProperties";
import { InputElementType } from "@teamfabric/copilot-ui/dist/components/BaseInputComponent";
import {
  CHANNEL_AVAILABLE_CURRENCIES_API,
  CHANNEL_AVAILABLE_LOCALES_API,
  CHANNEL_LIST_API,
} from "../lib/constants/constants";
import { useHistory } from "react-router-dom";

import {
  ChannelDetailsTypeProps,
  ChannelDetailsWidgetProps,
  EditMode,
  channelStatus,
} from "../Pages/utils/types";
import {
  getMultiChannelAPI,
  multichannelAPI,
} from "../Pages/utils/multiChannelAPI";
import { MessageType } from "@teamfabric/copilot-ui/dist/components/BaseInputComponent/BaseInputComponent.type";
import { generateRequestId } from "../Pages/utils/random-generator";
import ChannelDetailsLoader from "./channelDetailsLoader";
import { FlagIcons } from "@teamfabric/copilot-ui";
import { validateFormFields } from "../Pages/utils/form";

const ChannelDetailsWidget = ({
  headText,
  subHeadText,
  readOnly = false,
  addArchivedButton = false,
  errorLoadingData = undefined,
  isPageLoading = false,
  channelDetailsProps = {
    isDefault: false,
    status: CHANNEL_STATUS.INACTIVE,
    id: "",
    name: "",
    createdAt: "",
    updatedAt: "",
    metadata: {
      defaultCurrency: "",
      defaultLocale: "",
      currencies: [],
      locales: [],
    },
  },
  createNew = false,
  handleCreateNewChannel,
}: ChannelDetailsWidgetProps) => {
  const [channelDetails, setChannelDetails] =
    useState<ChannelDetailsTypeProps>(channelDetailsProps);
  const [locales, setLocales] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [isDefaultChecked, SetIsDefaultChecked] = useState(
    channelDetails.isDefault
  );
  const [showPopup, setShowPopup] = useState(false);
  const [modalHeaderText, setModalHeaderText] = useState("");
  const [modalDescriptionText, setModalDescriptionText] = useState("");
  const [modalPrimaryButton, setModalPrimaryButton] = useState("");
  const [localError, setLocalError] = useState("");
  const [currencyError, setCurrencyError] = useState("");
  const [messageType, setMessageType] = useState<MessageType>("normal");
  const [editMode, setEditMode] = useState<EditMode>(EditMode.NONE);
  const [isLoading, setIsLoading] = useState<boolean>(isPageLoading);
  const [formError, setFormError] = useState<Record<string, string>>({});

  const history = useHistory();
  const showToast = useToast();
  const chDetailsNoUpdate = channelDetailsProps;

  const clearLocalError = () => {
    setMessageType("normal");
    setLocalError("");
  };
  const clearCurrencyError = () => {
    setMessageType("normal");
    setCurrencyError("");
  };

  useEffect(() => {
    clearLocalError();
    clearCurrencyError();
    const fetchDataAndSetData = async (url: string, isLocale: boolean) => {
      try {
        const response = await getMultiChannelAPI(url);
        isLocale ? setLocales(response?.data || []) : setCurrencies(response?.data || []);
      } catch (error) {
        const message = error?.response?.data?.message || DEFAULT_ERROR_MESSAGE;
        isLocale ? setLocalError(message) : setCurrencyError(message);
        setMessageType("error");
      }
    };
    fetchDataAndSetData(CHANNEL_AVAILABLE_LOCALES_API, true);
    fetchDataAndSetData(CHANNEL_AVAILABLE_CURRENCIES_API, false);
    setChannelDetails(channelDetailsProps);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    setIsLoading(isPageLoading);
  }, [isPageLoading]);

  useEffect(() => {
    if (!createNew) {
      setChannelDetails(channelDetailsProps);
    }
  }, [channelDetailsProps]);

  const getStatusTooltipText = () => {
    let tooltipText = "";
    if (channelDetails.isDefault === true) {
      tooltipText = CHANNEL_TOOLTIP_MESSAGES.CANT_SET_TO_INACTIVE;
    } else if (
      channelDetails.isDefault === false &&
      channelDetails.status === CHANNEL_STATUS.ACTIVE
    ) {
      tooltipText = CHANNEL_TOOLTIP_MESSAGES.DEACTIVATE_MESSAGE;
    } else if (
      channelDetails.isDefault === false &&
      channelDetails.status === CHANNEL_STATUS.INACTIVE
    ) {
      tooltipText = CHANNEL_TOOLTIP_MESSAGES.ACTIVATE_MESSAGE;
    }
    return tooltipText;
  };

  const getDefaultTooltipText = () => {
    let tooltipText = "";
    if (chDetailsNoUpdate.isDefault === true) {
      tooltipText = CHANNEL_TOOLTIP_MESSAGES.SET_DEFAULT_MESSAGE;
    } else if (
      channelDetails.isDefault === false &&
      channelDetails.status === CHANNEL_STATUS.INACTIVE
    ) {
      tooltipText = CHANNEL_TOOLTIP_MESSAGES.CANT_SET_DEFAULT_MESSAGE;
    }
    return tooltipText;
  };
  const getArchiveTooltipText = () => {
    return channelDetails.isDefault
      ? CHANNEL_TOOLTIP_MESSAGES.CANT_ARCHIVE
      : CHANNEL_TOOLTIP_MESSAGES.ARCHIVE_MESSAGE;
  };

  const renderCheckbox = () => {
    return (
      <div>
        <Checkbox
          checked={isDefaultChecked}
          dataTestid="checkbox-component"
          disabled={
            chDetailsNoUpdate.isDefault ||
            (!channelDetails.isDefault &&
              channelDetails.status !== CHANNEL_STATUS.ACTIVE) ||
            readOnly
          }
          label={SET_AS_DEFAULT}
          value={DEFAULT_TEXT}
          name={DEFAULT_TEXT}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            SetIsDefaultChecked(e.target.checked);
            updateChannelDetails({ isDefault: e.target.checked });
          }}
        ></Checkbox>
      </div>
    );
  };

  const localeOptions = locales.map((locale, index) => {
    return {
      id: `${index}`,
      label: locale.code,
      iconName: FlagIcons[`${locale?.country}Flag`]
        ? `${locale?.country}Flag`
        : "DefaultIcon",
      selected: channelDetails.metadata?.defaultLocale === locale.code,
      isAdditional: channelDetails.metadata?.locales.find(
        (code) => code === locale.code
      ),
    };
  });

  const currenciesOptions = (isMulti: boolean) => {
    return currencies.map((currency, index) => {
      const label = `${!isMulti ? currency.name + " - " : ""}${
        currency.symbol
      } ${currency.code}`;
      return {
        id: `${index}`,
        label: label,
        selected: channelDetails.metadata?.defaultCurrency === currency.code,
        isAdditional: channelDetails.metadata?.currencies.find(
          (code) => code === currency.code
        ),
      };
    });
  };

  const showSaveVersionOfModal = () => {
    //check if there are other changes than isDefault, so we show the 'Save changes' modal instead of 'Set as Default' modal
    for (const prop in channelDetails) {
      if (channelDetails.hasOwnProperty(prop)) {
        if (
          JSON.stringify(channelDetails[prop]) !==
          JSON.stringify(chDetailsNoUpdate[prop])
        ) {
          if (prop !== "isDefault") {
            return true;
          }
        }
      }
    }
    return false;
  };

  const handleSaveButton = () => {
    // do the form validation here, if missing data. stop here and show error
    const formValidationErrors = validateFormFields(channelDetails);
    const isFormValid = Object.keys(formValidationErrors)?.length === 0;
    if (!isFormValid) {
      setFormError(formValidationErrors);
      return;
    }
    if (
      channelDetails.isDefault &&
      !chDetailsNoUpdate.isDefault &&
      !showSaveVersionOfModal()
    ) {
      setModalHeaderText(
        MODAL_MESSAGES.SET_DEFAULT_HEADER.replace(
          "[channelName]",
          channelDetails.name
        )
      );
      setModalDescriptionText(
        MODAL_MESSAGES.SET_DEFAULT_DESCRIPTION.replace(
          "[channelName]",
          channelDetails.name
        )
      );
      setModalPrimaryButton(MODAL_MESSAGES.SET_DEFAULT_PRIMARY);
      setEditMode(EditMode.UPDATE);
      setShowPopup(true);
      //pass primary button action to modal
    } else if (createNew) {
      setIsLoading(true);
      handleCreateNewChannel(channelDetails);
    } else {
      setModalHeaderText(MODAL_MESSAGES.SAVE_MODAL_HEADER);
      setModalDescriptionText(MODAL_MESSAGES.SAVE_MODAL_DESCRIPTION);
      setModalPrimaryButton(MODAL_MESSAGES.SAVE_MODAL_PRIMARY);
      setEditMode(EditMode.UPDATE);
      setShowPopup(true);
    }
  };

  const handleArchiveClick = () => {
    setModalHeaderText(MODAL_MESSAGES.ARCHIVE_HEADER);
    setModalDescriptionText(MODAL_MESSAGES.ARCHIVE_DESCRIPTION);
    setModalPrimaryButton(MODAL_MESSAGES.ARCHIVE_PRIMARY);
    setEditMode(EditMode.ARCHIVE);
    //to pass the primary functionality to modal
    setShowPopup(true);
  };

  const updateChannelDetails = (props: any) => {
    setChannelDetails((prevDetails) => ({
      ...prevDetails,
      ...props,
    }));
  };
  const showToastMessage = (message) => {
    showToast({
      id: "toast",
      label: message,
      isDismissable: true,
      showIcon: true,
    });
  };
  const handleChannelUpdateAPIRequest = async (data) => {
    try {
      setIsLoading(true);
      setShowPopup(false);
      await multichannelAPI.put(
        `${CHANNEL_LIST_API}/${channelDetails.id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "x-fabric-request-id": generateRequestId(),
          },
        }
      );
      showToastMessage(channelDetails?.status === channelStatus.Archived ? SUCCESSFUL_ARCHIVE_CHANNEL : CHANGES_SAVED_SUCCESS);
      history.push(COPILOT_ROUTES.settings.channels.basePath);
      setIsLoading(true);
    } catch (e) {
      showToastMessage(FAILURE_ARCHIVE_CHANNEL);
      setIsLoading(false);
    }
  };

  const handleModalConfirmClick = () => {
    switch (editMode) {
      case EditMode.ARCHIVE: {
        handleChannelUpdateAPIRequest({
          ...channelDetails,
          status: channelStatus.Archived,
        });
        break;
      }
      case EditMode.UPDATE: {
        handleChannelUpdateAPIRequest(channelDetails);
        break;
      }
      default: {
      }
    }
  };
  if (errorLoadingData !== undefined && errorLoadingData?.length > 0) {
    return (
      <Box padding={{ left: 6, right: 6, bottom: 6, top: 6 }}>
        {errorLoadingData}
      </Box>
    );
  }
  if (isLoading) {
    return <ChannelDetailsLoader />;
  }
  return (
    <Box padding={{ left: 6, right: 6, bottom: 6 }}>
      <StyledHead>
        <div>
          <StyledH4>{headText}</StyledH4>
          <StyledBodyText>
            {readOnly ? <Badge label={READ_ONLY}></Badge> : subHeadText}
          </StyledBodyText>
        </div>
        {!readOnly ? (
          <Box flex={{}}>
            {addArchivedButton && (
              <Box margin={{ right: 2 }}>
                <Tooltip text={getArchiveTooltipText()}>
                  <Button
                    text={ARCHIVE}
                    dataTestid="archive-button"
                    variant="tertiary"
                    isDisabled={channelDetails.isDefault}
                    onClick={() => {
                      handleArchiveClick();
                    }}
                  />
                </Tooltip>
              </Box>
            )}
            <Box margin={{ right: 2 }}>
              <Button
                text={CANCEL_BUTTON}
                variant="secondary"
                onClick={() => {
                  history.push(COPILOT_ROUTES.settings.channels.basePath);
                }}
              />
            </Box>
            <Button
              text={SAVE_BUTTON}
              variant="primary"
              onClick={() => {
                handleSaveButton();
              }}
            />
          </Box>
        ) : (
          <Button
            text={CLOSE}
            variant="secondary"
            onClick={() => {
              history.push(COPILOT_ROUTES.settings.channels.basePath);
            }}
          />
        )}
      </StyledHead>

      <Box
        border={{
          radius: 1,
          color: `${theme.color.grey[200]}`,
          width: "1px",
        }}
        padding={{ left: 4, right: 4, bottom: 4, top: 4 }}
      >
        <StyledH5>{CHANNEL_LABEL}</StyledH5>

        <Box margin={{ bottom: 4 }} width={`fit-content`}>
          <Tooltip text={getStatusTooltipText()}>
            <div>
              <Switch
                label={ACTIVE_LABEL}
                checked={channelDetails.status === CHANNEL_STATUS.ACTIVE}
                stateLabels={{ ON: ON_LABEL, OFF: OFF_LABEL }}
                dataTestid="switch-component"
                disabled={channelDetails.isDefault ? true : false || readOnly}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  updateChannelDetails({
                    status:
                      e.target.value === "false"
                        ? CHANNEL_STATUS.ACTIVE
                        : CHANNEL_STATUS.INACTIVE,
                  });
                }}
              ></Switch>
            </div>
          </Tooltip>
        </Box>
        <Box width={`fit-content`}>
          {chDetailsNoUpdate.isDefault === false &&
          channelDetails.status === CHANNEL_STATUS.ACTIVE ? (
            renderCheckbox()
          ) : (
            <Tooltip text={getDefaultTooltipText()}>{renderCheckbox()}</Tooltip>
          )}
        </Box>
        <Box margin={{ top: 4 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Input
              label={NAME_LABEL}
              message={formError?.name?.length > 0 ? formError?.name : ""}
              messageType={formError?.name?.length > 0 ? "error" : "normal"}
              required={true}
              inputProps={{
                value: channelDetails.name,
                disabled: readOnly,
                dataTestid: "input-component",
                onChange: (event: ChangeEvent<InputElementType>) => {
                  updateChannelDetails({ name: event.target.value });
                },
              }}
            ></Input>
            {!createNew && (
              <div style={{marginLeft: 16, marginTop: -1}}>
                <Input
                  width="383px"
                  label={"ID"}
                  readOnlyWithCopy
                  inputProps={{
                    value: channelDetails?.id,
                  }}
                />
              </div>
            )}
          </div>
        </Box>
      </Box>

      <Box
        border={{
          radius: 1,
          color: `${theme.color.grey[200]}`,
          width: "1px",
        }}
        padding={{ left: 4, right: 4, bottom: 4, top: 4 }}
        margin={{ top: 4 }}
      >
        <StyledH5>{LOCALES}</StyledH5>

        <Box flex={{}}>
          <Box margin={{ right: 4 }}>
            <Dropdown
              label={DEFAULT_LOCALE}
              required={true}
              placeholder="List Item"
              dataTestid="default-locale"
              message={localError || formError.defaultLocale}
              messageType={
                localError.length || formError.defaultLocale?.length
                  ? "error"
                  : undefined
              }
              options={localeOptions}
              disabled={readOnly}
              value={localeOptions.find((locale) => locale.selected)}
              onChange={(option: DropdownOptionProps) => {
                clearLocalError();
                updateChannelDetails({
                  metadata: {
                    ...channelDetails.metadata,
                    locales: !channelDetails.metadata.locales.includes(
                      option.label
                    )
                      ? channelDetails.metadata.locales.concat(option.label)
                      : channelDetails.metadata.locales,
                    defaultLocale: option.label,
                  },
                });
              }}
              onBlur={(event: React.FocusEvent<Element, Element>) => {
                if (channelDetails.metadata.defaultLocale === "") {
                  setLocalError(DEFAULT_LOCALE_MISSING_ERROR);
                  setMessageType("error");
                }
              }}
            ></Dropdown>
          </Box>
          <MultiSelect
            label={ADDITIONAL_LOCALES}
            required={false}
            disabled={readOnly}
            dataTestid="additional_locales"
            options={localeOptions || []}
            value={localeOptions?.filter(
              (locale) => !locale?.selected && locale?.isAdditional
            )}
            onChange={(options: MultiSelectOptionProp[]) => {
              updateChannelDetails({
                metadata: {
                  ...channelDetails.metadata,
                  locales: [channelDetails.metadata.defaultLocale].concat(
                    options.map((option) => option.label)
                  ),
                },
              });
            }}
            onClear={() => {
              updateChannelDetails({
                metadata: {
                  ...channelDetails.metadata,
                  locales: [channelDetails.metadata.defaultLocale],
                },
              });
            }}
          ></MultiSelect>
        </Box>
      </Box>

      <Box
        border={{
          radius: 1,
          color: `${theme.color.grey[200]}`,
          width: "1px",
        }}
        padding={{ left: 4, right: 4, bottom: 4, top: 4 }}
        margin={{ top: 4 }}
      >
        <StyledH5>{CURRENCIES}</StyledH5>

        <Box flex={{}}>
          <Box margin={{ right: 4 }}>
            <Dropdown
              label={DEFAULT_CURRENCY}
              required={true}
              disabled={readOnly}
              placeholder="List Item"
              dataTestid="default_currency"
              message={currencyError || formError.defaultCurrency}
              messageType={
                currencyError || formError.defaultCurrency ? "error" : "normal"
              }
              options={currenciesOptions(false)}
              value={currenciesOptions(false).find(
                (currency) => currency.selected
              )}
              onChange={(option: DropdownOptionProps) => {
                clearCurrencyError();
                updateChannelDetails({
                  metadata: {
                    ...channelDetails.metadata,
                    currencies: !channelDetails.metadata.currencies.includes(
                      currencies[option.id].code
                    )
                      ? channelDetails.metadata.currencies.concat(
                          currencies[option.id].code
                        )
                      : channelDetails.metadata.currencies,
                    defaultCurrency: currencies[option.id].code,
                  },
                });
              }}
              onBlur={() => {
                if (channelDetails.metadata.defaultCurrency === "") {
                  setCurrencyError(DEFAULT_CURRENCY_MISSING_ERROR);
                  setMessageType("error");
                }
              }}
            ></Dropdown>
          </Box>
          <MultiSelect
            label={ADDITIONAL_CURRENCIES}
            required={false}
            options={currenciesOptions(true) || []}
            dataTestid="additional_currencies"
            disabled={readOnly}
            value={currenciesOptions(true).filter(
              (currency) => !currency.selected && currency.isAdditional
            )}
            onChange={(options: MultiSelectOptionProp[]) => {
              updateChannelDetails({
                metadata: {
                  ...channelDetails.metadata,
                  currencies: [channelDetails.metadata.defaultCurrency].concat(
                    options.map((option) => currencies[option.id].code)
                  ),
                },
              });
            }}
            onClear={() => {
              updateChannelDetails({
                metadata: {
                  ...channelDetails.metadata,
                  currencies: [channelDetails.metadata.defaultCurrency],
                },
              });
            }}
          ></MultiSelect>
        </Box>
      </Box>

      <Modal
        size="small"
        headerText={modalHeaderText}
        description={modalDescriptionText}
        isVisible={showPopup}
        onClose={() => {
          setShowPopup(false);
        }}
        footerButtons={[
          {
            text: MODAL_MESSAGES.SECONDARY_BUTTON,
            variant: "secondary",
            onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              setShowPopup(false);
            },
          },
          {
            text: modalPrimaryButton,
            variant: "primary",
            onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              handleModalConfirmClick();
            },
          },
        ]}
      ></Modal>
    </Box>
  );
};

export default ChannelDetailsWidget;
