export const CHANNELS_HEAD = "Channels";
export const CHANNELS_SUBHEAD = "View and manage your channels";
export const CHANNELS_COL1 = "Channel name";
export const CHANNELS_COL2 = "Locales";
export const CHANNELS_COL3 = "Currencies";
export const CHANNELS_COL4 = "Status";
export const CHANNELS_EMPTY_HEADER_TEXT = "No results found";
export const CHANNELS_EMPTY_ERROR_DESCRIPTION = "Failed to fetch channels.";
export const CHANNELS_GET_PERMISSION_MISSING = "Failed to fetch Channels. Please check permissions";
export const DEFAULT_ERROR_MESSAGE = "Failed to fetch data";
export const DEFAULT_LOCALE_MISSING_ERROR = "Default locale missing";
export const DEFAULT_CURRENCY_MISSING_ERROR = "Default currency missing";

export const CREATE_CHANNEL = "Create Channel";
export const CHANGES_SAVED_SUCCESS = "Changes successfully saved";
export const DEFAULT_CHANNEL = "Default Channel";
export const DEFAULT_TEXT = "Default";
export const SET_AS_DEFAULT = "Set as default";
export const PAGE_SIZE = 10;
export const CANCEL_BUTTON = "Cancel";
export const SAVE_BUTTON = "Save";
export const ARCHIVE = "Archive";
export const CLOSE = "Close";
export const LOCALES = "Locales";
export const CURRENCIES = "Currencies";
export const READ_ONLY = "Read-only";
export const CHANNEL_LABEL = "Channel";
export const ACTIVE_LABEL = "Active";
export const ON_LABEL = "Yes";
export const OFF_LABEL = "No";
export const NAME_LABEL = "Name";
export const SUCCESSFUL_ARCHIVE_CHANNEL = "Channel successfully archived.";
export const FAILURE_ARCHIVE_CHANNEL = "Channel archive failed.";
export const DEFAULT_LOCALE = "Default locale";
export const DEFAULT_CURRENCY = "Default currency";

export const ADDITIONAL_LOCALES = "Additional locales";
export const ADDITIONAL_CURRENCIES = "Additional currencies";

export const CHANNEL_TOOLTIP_MESSAGES = {
  CANT_SET_TO_INACTIVE: "The default channel cannot be set to inactive",
  DEACTIVATE_MESSAGE:
    "Deactivating a channel will hide it from any API clients.",
  ACTIVATE_MESSAGE:
    "Activating a channel will make it visible to any API clients.",
  SET_DEFAULT_MESSAGE: "Set another channel as default to change this setting.",
  CANT_SET_DEFAULT_MESSAGE: "An inactive channel cannot be set to default",
  CANT_ARCHIVE: "You cannot archive the default channel.",
  ARCHIVE_MESSAGE: "API clients cannot use archived channels.",
};
export const CHANNEL_DETAILS_SUBHEAD =
  "Updating this information will affect any API client, such as a storefront, using this channel.";

export const CHANNEL_STATUS = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  ARCHIVED: "ARCHIVED",
};

export const MODAL_MESSAGES = {
  UNARCHIVE_HEADER: "Unarchive Channel?",
  UNARCHIVE_DESCRIPTION:
    "Channel will be set to inactive upon unarchiving. Do you want to proceed?",
  UNARCHIVE_PRIMARY: "Yes, unarchive",

  ARCHIVE_HEADER: "Archive this channel?",
  ARCHIVE_DESCRIPTION:
    "Archived channels cannot be used by fabric apps or external… Do you want to proceed?",
  ARCHIVE_PRIMARY: "Yes, Archive",

  SET_DEFAULT_HEADER: "Set [channelName] as default? ",
  SET_DEFAULT_DESCRIPTION:
    "[channelName] will replace your current default channel. Do you want to proceed?",
  SET_DEFAULT_PRIMARY: "Yes, Confirm",

  SAVE_MODAL_HEADER: "Apply changes?",
  SAVE_MODAL_DESCRIPTION:
    "These changes can affect API clients, such as storefronts, using this channel. Do you want to proceed?",
  SAVE_MODAL_PRIMARY: "Yes, Apply",

  LEAVE_PAGE_HEADER: "Leave page without saving?",
  LEAVE_PAGE_DESCRIPTION:
    "Your haven’t finished saving your changes, they’ll be lost if you leave now.  Do you want to proceed?",
  LEAVE_PAGE_PRIMARY: "Yes, leave",
  LEAVE_PAGE_SECONDARY: "No, stay on page",

  SECONDARY_BUTTON: "Cancel"
};
