export enum channelStatus {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
  Archived = "ARCHIVED",
}

export type ChannelListProps = {
  isLoading: boolean;
  error: string;
  channelList: any[];
};