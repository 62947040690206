import { ChannelDetailsTypeProps } from "./types";

export const validateFormFields = (formData: ChannelDetailsTypeProps) => {
    const formError: Record<string, string> = {};
    if (!formData.name) {
        formError.name = "Please add channel name"
    }
    if (!formData?.metadata?.defaultCurrency) {
        formError.defaultCurrency = "Please add default currency"
    }
    if (!formData.metadata?.defaultLocale) {
        formError.defaultLocale = "Please add default locale"
    }
    return formError;
}