const returnAccountItem = () => {
  const accountId = sessionStorage.getItem("accountId");
  const accounts = sessionStorage.getItem("accounts") ? JSON.parse(sessionStorage.getItem("accounts")) : [];

  const accountItem = accounts?.filter((account) => account.id === accountId);
  return accountItem?.length > 0 ? accountItem[0] : {};
};

export const validatePermission = (permission: string) => {
  const accountItem = returnAccountItem();
  return accountItem?.permissions?.indexOf(permission) > -1;
};
