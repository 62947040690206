import axios from "axios";
import { DEV_BASE_URL } from "../../lib/constants/constants";

export const isLocal = window.location.hostname === "localhost";

export const multichannelAPI = axios.create({
  baseURL: DEV_BASE_URL, //: `${process.env.BASE_URL}`, //"https://stage.api.fabric.inc/v3/multichannel"
  responseType: "json",
  headers: {
    accept: "application/json",
    "x-fabric-tenant-id": sessionStorage.getItem("accountId"),
    Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    "Content-Type": "application/json"
  },
});

export const getMultiChannelAPI = async (url: string) => {
    return await multichannelAPI.get(url);
};
