import {
  Box,
  SkeletonBody1,
  SkeletonButton,
  SkeletonH4,
  SkeletonH5,
  theme,
} from "@teamfabric/copilot-ui";
import { StyledHead } from "../Pages/ChannelList/style";

const ChannelDetailsLoader = () => {
  return (
    <Box padding={{ left: 6, right: 6, bottom: 6 }}>
      <Box padding={{ bottom: 6 }}>
        <StyledHead>
          <div>
            <Box padding={{ bottom: 4 }}>
              <SkeletonH4 />
            </Box>
            <SkeletonBody1 />
          </div>
          <Box flex={{}}>
            <Box margin={{ right: 4 }}>
              <SkeletonButton />
            </Box>
            <Box margin={{ right: 4 }}>
              <SkeletonButton />
            </Box>
            <SkeletonButton />
          </Box>
        </StyledHead>
      </Box>
      <Box
        border={{
          radius: 1,
          color: `${theme.color.grey[200]}`,
          width: "1px",
        }}
        padding={{ left: 4, right: 4, bottom: 4, top: 4 }}
      >
        <Box padding={{ bottom: 4 }}>
          <SkeletonH4 />
        </Box>
        <Box margin={{ bottom: 4 }} width={`fit-content`}>
          <SkeletonButton />
        </Box>
        <Box margin={{ bottom: 4 }} width={`fit-content`}>
          <SkeletonButton />
        </Box>
        <Box margin={{ top: 4 }}>
          <SkeletonButton />
        </Box>
      </Box>

      <Box
        border={{
          radius: 1,
          color: `${theme.color.grey[200]}`,
          width: "1px",
        }}
        padding={{ left: 4, right: 4, bottom: 4, top: 4 }}
        margin={{ top: 4 }}
      >
        <SkeletonH5 />

        <Box flex={{}}>
        <Box margin={{ right: 4 }}>
            <SkeletonButton width={330} />
          </Box>
          <Box margin={{ right: 4 }}>
            <SkeletonButton width={330} />
          </Box>
          <SkeletonButton width={330} />
        </Box>
      </Box>

      <Box
        border={{
          radius: 1,
          color: `${theme.color.grey[200]}`,
          width: "1px",
        }}
        padding={{ left: 4, right: 4, bottom: 4, top: 4 }}
        margin={{ top: 4 }}
      >
        <SkeletonH5 />
        <Box flex={{}}>
          <Box margin={{ right: 4 }}>
            <SkeletonButton width={330} />
          </Box>
          <Box margin={{ right: 4 }}>
            <SkeletonButton width={330} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default ChannelDetailsLoader;
