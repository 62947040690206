export enum channelStatus {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
  Archived = "ARCHIVED",
}

export enum EditMode {
  ARCHIVE = "ARCHIVE",
  UPDATE = "UPDATE",
  DEFAULT = "DEFAULT",
  ACTIVE = "ACTIVE",
  NONE = ""
}

export type ChannelListProps = {
  isLoading: boolean;
  error: string;
  channelList: any[];
};

export type ChannelDetailsTypeProps = {
  id?: string;
  name?: string;
  status?: string;
  isDefault?: boolean;
  createdAt?: string;
  updatedAt?: string;
  metadata?: {
    defaultCurrency?: string;
    defaultLocale?: string;
    currencies?: string[];
    locales?: string[];
  };
};
export type ChannelDetailsWidgetProps = {
  headText: string;
  subHeadText: string;
  readOnly?: boolean;
  addArchivedButton?: boolean;
  errorLoadingData?: string | undefined;
  channelDetailsProps?: ChannelDetailsTypeProps;
  createNew?: boolean;
  handleCreateNewChannel?: (data: ChannelDetailsTypeProps) => {}
  isPageLoading?: boolean;
};
