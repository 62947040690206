import { useEffect, useState } from "react";
import { COPILOT_ROUTES } from '@teamfabric/copilot-utilities';
import ChannelDetailsWidget from "../../Components/channelDetailsWidget";
import {
  CHANNEL_DETAILS_SUBHEAD,
  DEFAULT_ERROR_MESSAGE,
} from "../../lib/constants/textProperties";
import { CHANNEL_DETAILS_API, UPDATE_CHANNEL_PERMISSION } from "../../lib/constants/constants";
import { ChannelDetailsTypeProps } from "../utils/types";
import { getMultiChannelAPI } from "../utils/multiChannelAPI";
import { validatePermission } from "../utils/permissions";
import ChannelDetailsLoader from "../../Components/channelDetailsLoader";

const ChannelDetails = () => {
  const searchParams = new URLSearchParams(document.location.search);
  const [channelData, setChannelData] = useState<ChannelDetailsTypeProps>();
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
  const [error, setError] = useState(undefined);
  const allowChannelUpdate = validatePermission(UPDATE_CHANNEL_PERMISSION);

  useEffect(() => {
    window.updateBreadcrumb([
      {
        label: 'Home',
        url: COPILOT_ROUTES.settings.account.basePath
      },
      {
        label: 'Settings',
        url: COPILOT_ROUTES.settings.basePath,
      },
      {
        label: 'Channels',
        url: COPILOT_ROUTES.settings.channels.basePath,
      }
    ]);
    setError(undefined);
    fetchDataAndSetData();
  }, []);

  const fetchDataAndSetData = async () => {
    try {
      setIsPageLoading(true);
      const response = await getMultiChannelAPI(
        CHANNEL_DETAILS_API.replace(
          "{channelId}",
          searchParams.get("channelId")
        )
      );
      setChannelData(response.data);
      window.updateBreadcrumb([
        {
          label: 'Home',
          url: COPILOT_ROUTES.settings.account.basePath
        },
        {
          label: 'Settings',
          url: COPILOT_ROUTES.settings.basePath,
        },
        {
          label: 'Channels',
          url: COPILOT_ROUTES.settings.channels.basePath,
        }, {
          label: response?.data?.name,
          url: COPILOT_ROUTES.settings.channels.basePath
        }
      ]);
      setIsPageLoading(false);
    } catch (error) {
      //use mock data for now
      setError(error?.response?.data?.message || DEFAULT_ERROR_MESSAGE);
      setIsPageLoading(false);
    }
  };

  if (isPageLoading) {
    return (
      <ChannelDetailsLoader />
    )
  }
  return (
    <ChannelDetailsWidget
      isPageLoading={isPageLoading}
      readOnly={!allowChannelUpdate}
      headText={channelData?.name}
      subHeadText={CHANNEL_DETAILS_SUBHEAD}
      addArchivedButton={true}
      errorLoadingData={error}
      channelDetailsProps={channelData}
    ></ChannelDetailsWidget>
  );
};

export default ChannelDetails;
