import { useToast } from "@teamfabric/copilot-ui";
import { COPILOT_ROUTES } from "@teamfabric/copilot-utilities";
import ChannelDetailsWidget from "../../Components/channelDetailsWidget";
import { CHANNEL_LIST_API } from "../../lib/constants/constants";
import { multichannelAPI } from "../utils/multiChannelAPI";
import { generateRequestId } from "../utils/random-generator";
import { ChannelDetailsTypeProps } from "../utils/types";
import { useHistory } from "react-router";
import { CHANGES_SAVED_SUCCESS } from "../../lib/constants/textProperties";
import { useState } from "react";

const CreateChannel = () => {
  const history = useHistory();
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
  window.updateBreadcrumb([
    {
      label: 'Home',
      url: COPILOT_ROUTES.settings.account.basePath,
    },
    {
      label: 'Settings',
      url: COPILOT_ROUTES.settings.basePath,
    },
    {
      label: 'Channels',
      url: COPILOT_ROUTES.settings.channels.basePath,
    }, {
      label: 'Create',
      url: COPILOT_ROUTES.settings.channels.create,
    },
  ]);
  const showToast = useToast();
  const handleSaveNewChannel = async (data: ChannelDetailsTypeProps) => {
    try {
      setIsPageLoading(true);
      const apiInput = {
        name: data.name,
        status: data.status,
        isDefault: data.isDefault,
        metadata: data.metadata,
      };
      await multichannelAPI.post(CHANNEL_LIST_API, apiInput, {
        headers: {
          "Content-Type": "application/json",
          "x-fabric-request-id": generateRequestId(),
        },
      });
      showToast({
        id: "create-successful-toast",
        label: CHANGES_SAVED_SUCCESS,
        isDismissable: true,
        showIcon: true,
      });
      setIsPageLoading(false);
      history.push(COPILOT_ROUTES.settings.channels.basePath);
    } catch (error) {
      setIsPageLoading(false);
      showToast({
        id: "create-error-toast",
        label: error?.response?.data?.message,
        isDismissable: true,
        showIcon: true,
        variant: "error",
      });
    }
  };
  return (
    <ChannelDetailsWidget
      isPageLoading={isPageLoading}
      handleCreateNewChannel={handleSaveNewChannel}
      createNew
      headText="Create Channel"
      subHeadText=""
    />
  );
};
export default CreateChannel;
