export const DEV_BASE_URL = "https://stage.api.fabric.inc/v3/multichannel";

export const CHANNEL_LIST_API = "/merchant-settings/channels";
export const CHANNEL_AVAILABLE_CURRENCIES_API = "/merchant-settings/currencies";
export const CHANNEL_AVAILABLE_LOCALES_API = "/merchant-settings/locales";
export const CHANNEL_DETAILS_API = "/merchant-settings/channels/{channelId}";

export const MAX_NUM_OF_CHANNELS = 100;
export const CREATE_CHANNEL_PERMISSION = "platform:v3:merchant.channel:create";
export const GET_CHANNELS_PERMISSION = "platform:v3:merchant.channels:get";
export const UPDATE_CHANNEL_PERMISSION = "platform:v3:merchant.channel:update";