import { useEffect, useState } from "react";
import { COPILOT_ROUTES } from '@teamfabric/copilot-utilities';
import { multichannelAPI } from "../utils/multiChannelAPI";
import { channelStatus } from "../utils/types";
import {
  CHANNEL_LIST_API,
  GET_CHANNELS_PERMISSION,
  MAX_NUM_OF_CHANNELS,
} from "../../lib/constants/constants";
import {
  CHANNELS_EMPTY_ERROR_DESCRIPTION,
  CHANNELS_GET_PERMISSION_MISSING,
} from "../../lib/constants/textProperties";
import ChannelList from "./ChannelList";
import { validatePermission } from "../utils/permissions";

const ChannelListPage = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [channelList, setChannelList] = useState([]);

  const showChannelsList = validatePermission(GET_CHANNELS_PERMISSION);

  window.updateBreadcrumb([
    {
      label: 'Home',
      url: COPILOT_ROUTES.settings.account.basePath
    },
    {
      label: 'Settings',
      url: COPILOT_ROUTES.settings.basePath,
    },
    {
      label: 'Account Settings',
      url: COPILOT_ROUTES.settings.account.basePath,
    },
    {
      label: 'Channels',
      url: COPILOT_ROUTES.settings.channels,
    }
  ]);
  useEffect(() => {
    if (showChannelsList) {
      getChannelListAPI();
    } else {
      setError(CHANNELS_GET_PERMISSION_MISSING);
    }
  }, []);

  const getChannelListAPI = async () => {
    setLoading(true);
    try {
      // API Call
      const response = await multichannelAPI.get(CHANNEL_LIST_API, {
        params: {
          status: `${channelStatus.Active},${channelStatus.Inactive},${channelStatus.Archived}`,
          size: MAX_NUM_OF_CHANNELS,
          start: 0,
        },
      });
      setChannelList(response?.data?.data || []);
      setLoading(false);
    } catch (error) {
      setError(
        error?.response?.data?.message || CHANNELS_EMPTY_ERROR_DESCRIPTION
      );
      setLoading(false);
    }
  };
  return (
    <ChannelList
      isLoading={loading}
      error={error}
      channelList={channelList}
    ></ChannelList>
  );
};
export default ChannelListPage;
